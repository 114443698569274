import React from 'react'
import { Navigation } from '../common/navigation'
import { Footer } from '../common/footer'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { Button } from '../common/button'
import { CMSWrapper } from '../components/cmsWrapper'
import { AREA_CARE_PATH } from '../config/constants'
import { useAreaContext } from '../common/contexts/areaContext'

export default function PageNotFound() {
  const basePath = useAreaContext()
  const nextQuestion =
    typeof window !== 'undefined' &&
    (window.localStorage.getItem('current-question') || '')

  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - 404 - Seite nicht gefunden" />
      <main>
        <Navigation />

        <header className="bg-img-yellow divider-left">
          <div className="row align-items-center">
            <div className="col-md-6 offset-md-3 col-10 offset-1 text-center">
              <h1>404 - Seite nicht gefunden</h1>
              <p>
                Wir konnten die von dir gesuchte Seite leider nicht finden.
                Vielleicht hilft dir einer der unten stehenden Links weiter.
              </p>
            </div>
          </div>
        </header>

        <section className="bg-blue divider-right">
          <div className="col-md-10 offset-md-1 col-12 text-center">
            <h2>Weiterführende Links:</h2>
            <dl>
              <dt className="mt-4">
                <Button color="dark-blue" href="/">
                  Stöbern
                </Button>
              </dt>
              <dd>Zurück zur Startseite</dd>
              <dt className="mt-4">
                <Button color="red" href="/spielen">
                  Spielen
                </Button>
              </dt>
              <dd>Spiele die jukebox</dd>
              <dt className="mt-4">
                <Button color="yellow" href="/stationen">
                  Stöbern
                </Button>
              </dt>
              <dd>Entdecke unsere Stationen</dd>
              <dt className="mt-4">
                <Button color="green" href="/#stuermen">
                  Stöbern
                </Button>
              </dt>
              <dd>Initiativ bewerben</dd>
              <dt className="mt-4">
                <Button color="dark-blue" href="/favoriten">
                  Favoriten
                </Button>
              </dt>
              <dd>Deine favorisierten Stationen</dd>
              <dt className="mt-4">
                {nextQuestion === '/spielen/gratulation' ? (
                  <Button color="green" href="/testergebnis">
                    Meine Top 10
                  </Button>
                ) : basePath === AREA_CARE_PATH ? (
                  <Button disabled color="green" href="/testergebnis">
                    Meine Top 10
                  </Button>
                ) : null}
              </dt>
              <dd>Dein persönliches Testergebnis</dd>
            </dl>
          </div>
        </section>

        <Footer />
      </main>
    </CMSWrapper>
  )
}
